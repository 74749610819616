<template>
  <div>
    <div class="row">
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>
    <div class="row">
      <div class="flex xs12" :class="{'md6': isOpen}">
        <va-card :title="$t('notifications.page.index')">
          <remote-table
            class="table-hover table-striped"
            :local="true"
            :columns="tableFields"
            :data="tableData"
            :loading="loading"
            :search="searchOptions"
            :top-options="top"
            :action-options="actions"
            :pagination="pagination"
            :params="serverParams"
            :queries="serverParams.queries"
            sort-field="name"
            sort-type="asc"
            crud-links="notifications"
            @view-item="viewItem"
            @update-data="updateData"
            @search-data="searchByName"
            @delete-item="tryDelete"
          />
        </va-card>
      </div>
      <div v-show="isOpen" class="flex xs12 md6">
        <va-card :title="$t('tables.headings.message')">
          {{ message }}
        </va-card>
      </div>
    </div>
  </div>
</template>
<script>
const RemoteTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/RemoteTable')

export default {
  name: 'user-notifications',
  components: {
    RemoteTable,
  },
  data () {
    return {
      isOpen: false,
      error: false,
      tableData: [],
      pagination: {},
      top: [],
      actions: ['view', 'delete'],
      searchQuery: '',
      loading: false,
      message: '',
      searchOptions: {
        enabled: true,
        trigger: 'enter',
        placeholder: this.$t('tables.actions.search'),
      },
      serverParams: {
        queries: '',
      },
    }
  },
  computed: {
    notificationsStatus () {
      return [
        { name: this.$t('notifications.status.unreaded'), value: 0 },
        { name: this.$t('notifications.status.readed'), value: 1 },
      ]
    },
    tableFields () {
      return [
        {
          name: 'message',
          title: this.$t('tables.headings.message'),
          callback: this.truncateMessage,
        },
        {
          name: 'status',
          title: this.$t('tables.headings.status'),
          sortField: 'status',
        },
        {
          name: 'created_at',
          title: this.$t('tables.headings.date'),
          callback: this.dateLabel,
          sortField: 'created_at',
        },
        {
          name: '__slot:actions',
          visible: this.tableData.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  methods: {
    dateLabel (date) {
      return this.$date.format(date, 'dd/MM/yyyy')
    },
    truncateMessage (text) {
      const stop = 100
      return text.slice(0, stop) + (stop < text.length ? '...' : '')
    },
    viewItem (item) {
      console.log('is open', this.isOpen)
      this.isOpen = true
      this.message = item.message
      if (item.status !== 1) {
        this.markAsRead(item)
      }
    },
    fixData (data) {
      // let noDef = this.$t('tables.undefined')
      for (const d of data) {
        if (!d.user) {
          d.user = {
            name: '',
          }
        }
        let status = this.notificationsStatus.find(s => s.value === d.status)
        status = status || { name: '', value: -1 }
        d.status = this.$t(status.name)
      }
      return data
    },
    apiUrl (params) {
      let route = 'notifications/user'
      route += '?page=' + params.page || 0
      route += '&limit=' + params.perPage || 50
      if (params.sort && params.sort.field !== '') {
        let field = params.sort.field
        switch (field) {
          case 'user.name':
            field = 'User.name'
            break
        }
        route += '&sort=' + field
        route += '&direction=' + params.sort.type
      }
      if (params.queries) {
        route += params.queries
      }

      return route
    },
    async updateData (params) {
      params = params || this.serverParams
      this.loading = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(params))
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.error = true
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async markAsRead (i) {
      const item = {
        id: i.id,
        status: 1,
      }

      try {
        await this.$http.put('notifications/' + item.id, item)
      } catch (err) {
        console.log('Failed to set status', err)
        return
      }

      const status = this.notificationsStatus[1]
      i.status = this.$t(status.name)
    },
    async searchByName (name) {
      if (name === '') return this.updateData()

      let response = false
      try {
        response = await this.$http.get('notifications/user?q=' + name)
      } catch (err) {
        // console.log('Error searching notifications', err)
        this.loading = false
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.data.length || 0
      this.loading = false
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      this.loading = true
      try {
        await this.$http.delete('notifications/' + item.id)
      } catch (err) {
        // console.log('Error deleting notifications', err)
        this.loading = false
        return
      }
      this.updateData()
    },
  },
}
</script>
